export const GRAB_ACTION_UNAUTHORIZED = 'GRAB_ACTION_UNAUTHORIZED';

export const GRAB_LIST_FETCH_REQUEST = 'GRAB_LIST_FETCH_REQUEST';
export const GRAB_LIST_FETCH_ERROR = 'GRAB_LIST_FETCH_ERROR';
export const GRAB_LIST_FETCH_FAILURE = 'GRAB_LIST_FETCH_FAILURE';
export const GRAB_LIST_FETCH_SUCCESS = 'GRAB_LIST_FETCH_SUCCESS';

export const GRAB_LIST_SORT_BY_COUNTRY = 'GRAB_LIST_SORT_BY_COUNTRY';
export const GRAB_LIST_SORT_BY_NUMBER = 'GRAB_LIST_SORT_BY_NUMBER';

export const GRAB_DETAILS_FETCH_REQUEST = 'GRAB_DETAILS_FETCH_REQUEST';
export const GRAB_DETAILS_FETCH_SUCCESS = 'GRAB_DETAILS_FETCH_SUCCESS';
export const GRAB_DETAILS_FETCH_ERROR = 'GRAB_DETAILS_FETCH_ERROR';
export const GRAB_DETAILS_FETCH_FAILURE = 'GRAB_DETAILS_FETCH_FAILURE';

export const GRAB_ADD_STARTED = 'GRAB_ADD_STARTED';
export const GRAB_ADD_SUCCESS = 'GRAB_ADD_SUCCESS';
export const GRAB_ADD_ERROR = 'GRAB_ADD_ERROR';

export const GRAB_DELETE_STARTED = 'GRAB_DELETE_STARTED';
export const GRAB_DELETE_SUCCESS = 'GRAB_DELETE_SUCCESS';
export const GRAB_DELETE_ERROR = 'GRAB_DELETE_ERROR';
export const GRAB_DELETE_FAILURE = 'GRAB_DELETE_FAILURE';
