import fetch from 'cross-fetch';

import { push } from 'connected-react-router';

import {
  GRAB_ACTION_UNAUTHORIZED,
  GRAB_LIST_FETCH_REQUEST,
  GRAB_LIST_FETCH_ERROR,
  GRAB_LIST_FETCH_SUCCESS,
  GRAB_LIST_SORT_BY_COUNTRY,
  GRAB_LIST_SORT_BY_NUMBER,
  GRAB_DETAILS_FETCH_REQUEST,
  GRAB_DETAILS_FETCH_SUCCESS,
  GRAB_DETAILS_FETCH_ERROR,
  GRAB_DETAILS_FETCH_FAILURE,
  GRAB_ADD_STARTED,
  GRAB_ADD_SUCCESS,
  GRAB_ADD_ERROR,
  GRAB_DELETE_STARTED,
  GRAB_DELETE_SUCCESS,
  GRAB_DELETE_ERROR,
  GRAB_DELETE_FAILURE,
} from '../constants/grabs';

import {
  API_URL_BASE,
} from '../constants/index';


const API_URL = `${API_URL_BASE}/locations`;


/**
 * @param {string} url The URL to use in the fetch request
 * @param {object} options Additional fetch options
 * @param {string} options.method The HTTP method to use
 * @param {object} options.body_obj The JSON object to send on POST, PUT, etc.
 */
const fetchWithAuth = (url, state, options = {}) => {
  let fetch_options = {
    method: 'GET',
    headers: {},
  };

  const token = state.login.token;
  if (token) {
    fetch_options.headers['Authorization'] = `Bearer ${token}`;
  }

  if (options.method !== 'GET') {
    fetch_options.headers['Content-Type'] = 'application/json';
    fetch_options.method = options.method;
  }

  if (options.body_obj) {
    fetch_options.body = JSON.stringify(options.body_obj);
  }

  return fetch(url, fetch_options);
};


export const grabActionUnauthorized = message => ({
  type: GRAB_ACTION_UNAUTHORIZED,
  message,
});

export const grabListRequest = () => ({
  type: GRAB_LIST_FETCH_REQUEST,
});


export const grabListReceive = list =>({
  type: GRAB_LIST_FETCH_SUCCESS,
  received_at: Date.now(),
  payload: list,
});

export const grabListError = err => ({
  type: GRAB_LIST_FETCH_ERROR,
  failed_at: Date.now(),
  message: err.message,
});


export const grabListFetch = () => (dispatch, getState) => {
  // Set app state to indicate a fetch has started
  dispatch(grabListRequest());

  return fetchWithAuth(API_URL, getState()).then(
    response => {
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      return response.json();
    }
  ).then(
    json => {
      let list = json.data.map(grab => {
        return Object.assign({}, grab, {number: parseInt(grab.number)});
      });
      dispatch(grabListReceive(list));
    }
  ).catch(
    err => dispatch(grabListError(err))
  );
};


export const grabsSortByCountry = () => ({
  type: GRAB_LIST_SORT_BY_COUNTRY,
});


export const grabsSortByNumber = () => ({
  type: GRAB_LIST_SORT_BY_NUMBER,
});


const grabListShouldFetch = state => {
  const MIN_UPDATE_PERIOD = 10 * 1000;
  const grabs = state.grabs;

  if (grabs.last_updated) {
    const elapsed = Date.now() - grabs.last_updated;
    if (elapsed < MIN_UPDATE_PERIOD) {
      return false;
    }
  }
  else if (grabs.is_fetching) {
    return false;
  }
  else if ((!grabs.list) || (grabs.list.length === 0)) {
    return true;
  }
  return grabs.did_invalidate;
};

export const grabListFetchIfNeeded = () => (dispatch, getState) => {
  if (grabListShouldFetch(getState())) {
    return dispatch(grabListFetch());
  }
  return null;
};

export const grabDetailsRequest = () => ({
  type: GRAB_DETAILS_FETCH_REQUEST,
});

export const grabDetailsReceive = grab => ({
  type: GRAB_DETAILS_FETCH_SUCCESS,
  received_at: Date.now(),
  payload: grab,
});


export const grabDetailsError = err => ({
  type: GRAB_DETAILS_FETCH_ERROR,
  failed_at: Date.now(),
  message: err.message,
});


export const grabDetailsFailure = msg => ({
  type: GRAB_DETAILS_FETCH_FAILURE,
  failed_at: Date.now(),
  message: msg,
});


export const grabDetailsFetch = num => (dispatch, getState) => {
  // Set app state to indicate a fetch has started
  dispatch(grabDetailsRequest());

  return fetchWithAuth(`${API_URL}/${num}`, getState()).then(
    response => {
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      return response.json();
    }
  ).then(
    json => {
      let grab = json.data;
      grab.number = parseInt(grab.number);
      grab.lat = parseFloat(grab.lat);
      grab.lng = parseFloat(grab.lng);
      return dispatch(grabDetailsReceive(grab));
    }
  ).catch(
    err => dispatch(grabDetailsError(err))
  );
};


export const grabAddStarted = () => ({
  type: GRAB_ADD_STARTED,
  message: 'Started adding grab',
});


export const grabAddedSuccessfully = () => ({
  type: GRAB_ADD_SUCCESS,
  message: 'Grab added successfully',
});


export const grabAddError = err => ({
  type: GRAB_ADD_ERROR,
  message: err.message,
});



/**
 * @param {object} details The details of the object to add
 * @param {number} details.number The LandMatrix ID
 * @param {string} details.url The URL to the full info on LandMatrix
 * @param {string} details.country The country where the grab is found
 * @param {string} details.lat The latitude of the location
 * @param {string} details.lng The longitude of the location
 * @param {object} details.geojson The GeoJSON object showing fields on a map
 */
export const grabAddOrEdit = details => (dispatch, getState) => {
  // Set app state to indicate an add operation has started
  dispatch(grabAddStarted());

  return fetchWithAuth(API_URL, getState(), { method: 'POST', body_obj: details }).then(
    response => {
      if (!response.ok) {
        if (response.status === 401) {
          return dispatch(grabActionUnauthorized('Unauthorized'));
        }
        throw new Error('Network response was not ok.');
      }
      dispatch(grabListFetch());
      dispatch(grabAddedSuccessfully());
      dispatch(grabDetailsFetch(details.number));

      // Redirect to viewing the location
      return dispatch(push(`/locations/${details.number}`));
    }
  ).catch(
    err => dispatch(grabAddError(err))
  );
};



export const grabDeleteStarted = () => ({
  type: GRAB_DELETE_STARTED,
  message: 'Deletion of grab started',
});


export const grabDeletedSuccessfully = () => ({
  type: GRAB_DELETE_SUCCESS,
  message: 'Grab deleted successfully',
});


export const grabDeleteError = err => ({
  type: GRAB_DELETE_ERROR,
  message: err.message,
});


export const grabDeleteFailure = msg => ({
  type: GRAB_DELETE_FAILURE,
  message: msg,
});


/**
 * @param {number} num The number of the item to remove
 */
export const grabDelete = num => (dispatch, getState) => {
  // Set app state to indicate a delete operation has started
  dispatch(grabDeleteStarted());

  return fetchWithAuth(`${API_URL}/${num}`, getState(), { method: 'DELETE' }).then(
    response => {
      if (!response.ok) {
        if (response.status === 401) {
          return dispatch(grabActionUnauthorized('Unauthorized'));
        }
        if (response.status === 404) {
          return dispatch(grabDeleteFailure('Not found'));
        }
        throw new Error('Network response was not ok.');
      }
      dispatch(grabListFetch());
      return dispatch(grabDeletedSuccessfully());
    }
  ).catch(
    err => dispatch(grabDeleteError(err))
  );
};


/**
 * @param {number} num The number of the item to remove
 */
export const grabDeleteThenRedirect = num => dispatch =>
  dispatch(grabDelete(num)).then(
    action => {
      if (action.type === GRAB_DELETE_SUCCESS) {
        return dispatch(push('/locations'));
      }
      return null;
    }
  );

