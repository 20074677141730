import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../styles/App.css';

class Main extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Land Grabs</h1>
          <p>
            Land Grabs are the issue
          </p>
          <div>
            <Link className="App-link" to="/">Home</Link>
            {" | "}
            <Link className="App-link" to="/locations">Locations</Link>
          </div>
        </header>
      </div>
    );
  }
}

export default Main;
