import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_ERROR,
  LOGIN_LOGOUT,
  LOGIN_HIDE_MESSAGE,
} from '../constants/login';

const update = (old_obj, new_vals) => {
  return Object.assign({}, old_obj, new_vals);
};

const login = (
  state = {
    authenticated: false,
    email: null,
    token: null,
    message: null,
    errored: false,
    failed: false,
  }, action) => {
    switch (action.type) {
    case LOGIN_SUCCESS:
      return update(state, {
        authenticated: true,
        email: action.json.data.email,
        token: action.json.data.token,
        message: 'Successfully logged in',
        errored: false,
        failed: false,
      });
    case LOGIN_FAILURE:
      return update(state, {
        authenticated: false,
        message: action.json.error.message,
        errored: false,
        failed: true,
      });
    case LOGIN_ERROR:
      return update(state, {
        authenticated: false,
        message: action.message,
        errored: true,
        failed: false,
      });
    case LOGIN_REQUEST:
      return update(state, {
        authenticated: false,
        email: null,
        token: null,
        message: 'Starting login',
        errored: false,
        failed: false,
      });
    case LOGIN_LOGOUT:
      return update(state, {
        authenticated: false,
        email: null,
        token: null,
        message: 'Logged out',
        errored: false,
        failed: false,
      });
    case LOGIN_HIDE_MESSAGE:
      return update(state, {
        message: null,
      });
    default:
      return state;
    }
  };

export default login;
