import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { Alert, Button, Container, Row, Col } from 'react-bootstrap';


import {
  grabsSortByCountry,
  grabsSortByNumber,
  grabListFetchIfNeeded,
  grabDelete,
} from '../actions/grabdb';

const POINTER_STYLE = {
  cursor: 'pointer',
};


class LandGrabList extends Component {
  componentDidMount() {
    const { grabListFetchIfNeeded } = this.props;
    grabListFetchIfNeeded();
  }

  componentDidUpdate(prev_props) {
    const { grabListFetchIfNeeded } = this.props;
    grabListFetchIfNeeded();
  }

  render() {
    const { authenticated, grablist, is_fetching, last_updated, message, message_type,
            grabsSortByCountry, grabsSortByNumber, grabDelete } = this.props;

    let alert;
    if (message) {
      let variant = message_type;
      if (message_type === 'error') {
        variant = 'danger';
      }

      // When key changes, the component is created, so the message is
      // effectively "undismissed"
      alert = <Alert dismissible key={message} variant={variant}>{message}</Alert>;
    }

    let col_width=4;
    if (authenticated) {
      col_width=3;
    }

    return (
      <Container>
        <h2>List of Land Grabs</h2>
        {alert}
        {is_fetching && grablist.length === 0 && <h3>Loading...</h3>}
        {!is_fetching && grablist.length === 0 && <h3>No land grabs found</h3>}
        {grablist.length > 0 && (
          <div style={{ opacity: is_fetching ? 0.5 : 1 }}>
            {last_updated && (
              <span>
                Last updated at {new Date(last_updated).toLocaleTimeString()}.{' '}
              </span>
            )}
            <Row>
              <Col className="p-2" xs={col_width-1}>
                <strong style={POINTER_STYLE} onClick={grabsSortByNumber}>Number</strong>
              </Col>
              <Col className="p-2" xs={col_width+1}>
                <strong style={POINTER_STYLE} onClick={grabsSortByCountry}>Country</strong>
              </Col>
              <Col className="p-2" xs={col_width}>
                <strong>Link</strong>
              </Col>
              {authenticated &&
               <Col className="p-2" xs={col_width}>
                 <strong>Delete</strong>
               </Col>
              }
            </Row>
            {grablist.map((grab, i) => (
              <Row key={i}>
                <Col className="p-2" xs={col_width-1}>
                  <Link to={"/locations/" + grab.number}>{grab.number}</Link>
                </Col>
                <Col className="p-2" xs={col_width+1}>
                  {grab.country}
                </Col>
                <Col className="p-2" xs={col_width}>
                  <a href={grab.url}>LandMatrix</a>
                </Col>
                {authenticated &&
                 <Col className="p-2" xs={col_width}>
                   <Button
                     variant="danger"
                     size="sm"
                     onClick={ () => grabDelete(grab.number) }>
                     Delete
                   </Button>
                 </Col>
                }
              </Row>
              ))}
          </div>
        )}
      </Container>
    );
  }
}

LandGrabList.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  grablist:      PropTypes.array.isRequired,
  is_fetching:   PropTypes.bool.isRequired,
  last_updated:  PropTypes.number,
  message:       PropTypes.string,
  message_type:  PropTypes.string,
};

const mapStateToProps = (state) => ({
  authenticated: state.login.authenticated,
  is_fetching:   state.grabs.is_fetching,
  last_updated:  state.grabs.last_updated,
  grablist:      state.grabs.list,
  message_type:  state.grabs.message_type,
  message:       state.grabs.message,
});

const map_dispatch_to_props = {
  grabDelete,
  grabsSortByCountry,
  grabsSortByNumber,
  grabListFetchIfNeeded,
};

export default connect(mapStateToProps, map_dispatch_to_props)(LandGrabList);
