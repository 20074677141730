import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route} from 'react-router-dom';

const AuthOnlyRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    rest.authenticated === true ?
      <Component {...props} /> :
      <Redirect to='/' />
  )} />
);

const mapStateToProps = state => ({
  authenticated: state.login.authenticated,
});

export default connect(mapStateToProps)(AuthOnlyRoute);
