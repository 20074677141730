import { combineReducers } from 'redux';

// To use routers with a redux react app
import { connectRouter } from 'connected-react-router';

import {
  LOGIN_SUCCESS,
} from '../constants/login';

import grabs from './grabs';
import login from './login';

const maps = (state = { key: null }, action) => {
  switch (action.type) {
  case LOGIN_SUCCESS:
    return Object.assign({}, state, {
      key: action.json.data.gmaps,
    });
  default:
    return state;
  }
};

const addHistoryToReducers = history => combineReducers({
  router: connectRouter(history),
  grabs,
  login,
  maps,
});

export default addHistoryToReducers;

