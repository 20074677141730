import React from 'react';
import { connect } from 'react-redux';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavLink from 'react-bootstrap/NavLink';

import { LinkContainer } from 'react-router-bootstrap';

import LoginFormDropdown from './LoginFormDropdown';

const Navthing = props => (
  <Navbar bg="dark" expand="sm" variant="dark">
    <LinkContainer to="/">
      <LinkContainer to="/">
        <Navbar.Brand>Land Grabs</Navbar.Brand>
      </LinkContainer>
    </LinkContainer>
    <Navbar.Toggle />
    <Navbar.Collapse>
      <Nav className="mr-auto">
        <LinkContainer exact to="/">
          <NavLink>Home</NavLink>
        </LinkContainer>
        <LinkContainer exact to="/locations">
          <NavLink>Locations</NavLink>
        </LinkContainer>
        {props.authenticated &&
         <LinkContainer to="/locations/add">
           <NavLink>Add Grab</NavLink>
         </LinkContainer>
        }
      </Nav>
      <Nav>
        <LoginFormDropdown />
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

const mapStateToProps = state => ({
  authenticated: state.login.authenticated,
});

export default connect(mapStateToProps)(Navthing);
