import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import GoogleMapsContainer from './GoogleMapsContainer';

import {
  grabDeleteThenRedirect,
  grabDetailsFetch
} from '../actions/grabdb';

class LandGrabShow extends Component {
  componentDidMount() {
    const { grab_id, current, grabDetailsFetch } = this.props;
    if (!current || (parseInt(current.number) !== grab_id)) {
      grabDetailsFetch(grab_id);
    }
  }

  render() {
    const { authenticated, grab_id, current, maps_api_key,
            grabDeleteThenRedirect} = this.props;

    let details = <p>No entry found</p>;

    if (current) {
      let center = {lat: current.lat, lng: current.lng};

      let parsed_geojson = JSON.parse(current.geojson);

      let map_element = <span>Login to see map</span>;
      if (maps_api_key) {
        map_element = <span>Map Loading...</span>;
        if (current.number === grab_id) {
          console.log('Setting map_element to GoogleMap component');
          map_element = <GoogleMapsContainer
                          center={center}
                          maps_api_key={maps_api_key}
                          geojson={parsed_geojson} />;
        }
      }

      details = (
        <>
          <Row>
            <Col sm={12} md={6}>
              {map_element}
            </Col>
            <Col sm={12} md={6}>
              <Row>
                <Col className="m-1"><strong>Country</strong></Col>
                <Col className="m-1">{current.country}</Col>
              </Row>
              <Row>
                <Col className="m-1"><strong>Number</strong></Col>
                <Col className="m-1">{current.number}</Col>
              </Row>
              <Row>
                <Col className="m-1"><strong>Lat</strong></Col>
                <Col className="m-1">{current.lat}</Col>
              </Row>
              <Row>
                <Col className="m-1"><strong>Lng</strong></Col>
                <Col className="m-1">{current.lng}</Col>
              </Row>
              <Row>
                <Col className="m-1"><strong>Link</strong></Col>
                <Col className="m-1">
                  <a href={current.url}>LandMatrix</a>
                </Col>
              </Row>
              {authenticated &&
               <Row>
                 <Col className="m-1">
                   <LinkContainer to={'/locations/' + current.number + '/edit'}>
                     <Button className="m-1">Edit</Button>
                   </LinkContainer>
                 </Col>
                 <Col className="m-1">
                   <Button variant="danger"
                           onClick={ () => grabDeleteThenRedirect(current.number) }>
                     Delete
                   </Button>
                 </Col>
               </Row>
              }
            </Col>
          </Row>
        </>
      );
    }

    return (
      <Container>
        <h2>Land Grab Details</h2>
        {details}
      </Container>
    );
  }
}

LandGrabShow.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  grab_id:       PropTypes.number.isRequired,
  current:       PropTypes.shape({
    number:        PropTypes.number.isRequired,
    country:       PropTypes.string.isRequired,
    lat:           PropTypes.number.isRequired,
    lng:           PropTypes.number.isRequired,
    url:           PropTypes.string.isRequired,
    geojson:       PropTypes.string.isRequired,
  }),
  maps_api_key:  PropTypes.string,
};

const mapStateToProps = (state, own_props) => ({
  grab_id:       parseInt(own_props.match.params.id),
  authenticated: state.login.authenticated,
  current:       state.grabs.current,
  maps_api_key:  state.maps.key,
});

const map_dispatch_to_props = {
  grabDeleteThenRedirect,
  grabDetailsFetch,
};

export default connect(mapStateToProps, map_dispatch_to_props)(LandGrabShow);
