import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { Alert, Form, Button } from 'react-bootstrap';


import {
  grabDeleteThenRedirect,
  grabAddOrEdit,
  grabDetailsFetch,
} from '../actions/grabdb';

const DEFAULT_STATE = {
  loaded:  false,
  number:  "",
  country: "",
  lat:     "",
  lng:     "",
  url:     "",
  geojson: "",
};

class LandGrabAdd extends Component {
  constructor(props) {
    super(props);

    this.state = DEFAULT_STATE;
  }

  componentDidMount() {
    const { grab_id, current, grabDetailsFetch } = this.props;
    // Load the data if there's a grab ID and:
    // * There's no "current"
    // * The "current" is stale (points to something with a different ID)
    if (grab_id) {
      if (!current || (parseInt(current.number) !== grab_id)) {
        grabDetailsFetch(grab_id);
      }
      else {
        // Current is correct, use it and start
        this.setState({
          loaded:  true,
          number:  current.number,
          country: current.country,
          lat:     current.lat,
          lng:     current.lng,
          url:     current.url,
          geojson: current.geojson,
        });
      }
    }
  }

  componentDidUpdate(prev_props) {
    const { loaded } = this.state;
    const { grab_id, current } = this.props;

    // This should only happen once when the data loads
    if (grab_id && !prev_props.grab_id) {
      if (current && (current.number === grab_id) && !loaded) {
        this.setState({
          loaded:  true,
          number:  current.number,
          country: current.country,
          lat:     current.lat,
          lng:     current.lng,
          url:     current.url,
          geojson: current.geojson,
        });
      }
    }
    else if (!grab_id && prev_props.grab_id) {
      // Switched from Edit to Add
      this.setState(DEFAULT_STATE);
    }
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({[name]: value});
  }

  handleSubmit = event => {
    // JS destructuring on the left, shorthand object initialization on the right, then an IIFE
    const subset = (
      ({number, country, lat, lng, url, geojson}) => ({number, country, lat, lng, url, geojson})
    )(this.state);
    this.props.grabAddOrEdit(subset);
    event.preventDefault();
  }

  render() {
    const { grab_id, message, message_type,
            grabDeleteThenRedirect} = this.props;

    let alert;
    if (message) {
      let variant = message_type;
      if (message_type === 'error') {
        variant = 'danger';
      }

      // When key changes, the component is created, so the message is
      // effectively "undismissed"
      alert = <Alert dismissible key={message} variant={variant}>{message}</Alert>;
    }

    let form = (
      <>
        <Form onSubmit={e => this.handleSubmit(e)}>
          <Row>
            <Col>
              {message}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group controlId="number">
                <Form.Label>Land Matrix number</Form.Label>
                <Form.Control name="number"
                              type="number"
                              value={this.state.number}
                              onChange={this.handleChange}
                              placeholder="1234" />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group controlId="country">
                <Form.Label>Country</Form.Label>
                <Form.Control name="country"
                              type="country"
                              value={this.state.country}
                              onChange={this.handleChange}
                              placeholder="Canada" />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group controlId="lat">
                <Form.Label>Lat</Form.Label>
                <Form.Control name="lat"
                              type="lat"
                              value={this.state.lat}
                              onChange={this.handleChange}
                              placeholder="lat" />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group controlId="lng">
                <Form.Label>Lng</Form.Label>
                <Form.Control name="lng"
                              type="lng"
                              value={this.state.lng}
                              onChange={this.handleChange}
                              placeholder="lng" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="url">
                <Form.Label>Land Matrix url</Form.Label>
                <Form.Control name="url"
                              type="url"
                              value={this.state.url}
                              onChange={this.handleChange}
                              placeholder="http://landmatrix..." />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="geojson">
                <Form.Label>GeoJSON</Form.Label>
                <Form.Control as="textarea"
                              rows="12"
                              name="geojson"
                              type="geojson"
                              value={this.state.geojson}
                              onChange={this.handleChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Col>
            <Col>
              {grab_id ?
               <LinkContainer to={ '/locations/' + grab_id }>
                 <Button>Back to Show</Button>
               </LinkContainer> :
               <span></span>
              }
            </Col>
            <Col>
              {grab_id ?
               <Button variant="danger"
                       onClick={ () => grabDeleteThenRedirect(grab_id) }>
                 Delete
               </Button> :
               <span></span>
              }
            </Col>
          </Row>
        </Form>
      </>
    );

    let form_or_waiting = form;
    if (grab_id && !this.state.loaded) {
      // This is an edit, so wait for the data to load
      form_or_waiting = <h3>Loading...</h3>;
    }

    let add_or_edit = 'Add';
    if (grab_id) {
      add_or_edit = 'Edit';
    }

    return (
      <Container>
        <h2>{add_or_edit} Land Grab</h2>
        {alert}
        {form_or_waiting}
      </Container>
    );
  }
}

LandGrabAdd.propTypes = {
  grab_id:       PropTypes.number.isRequired,
  message:       PropTypes.string,
  message_type:  PropTypes.string,
  current:       PropTypes.shape({
    number:        PropTypes.number.isRequired,
    country:       PropTypes.string.isRequired,
    lat:           PropTypes.number.isRequired,
    lng:           PropTypes.number.isRequired,
    url:           PropTypes.string.isRequired,
    geojson:       PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state, own_props) => {
  let grab_id = null;
  if (own_props.match) {
    // This is an edit not an added
    grab_id = parseInt(own_props.match.params.id);
  }
  return {
    grab_id,
    message:       state.grabs.message,
    message_type:  state.grabs.message_type,
    current:       state.grabs.current,
  };
};

const map_dispatch_to_props = {
  grabDeleteThenRedirect,
  grabAddOrEdit,
  grabDetailsFetch,
};

export default connect(mapStateToProps, map_dispatch_to_props)(LandGrabAdd);
