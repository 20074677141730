import {
  GRAB_ACTION_UNAUTHORIZED,
  GRAB_LIST_FETCH_REQUEST,
  GRAB_LIST_FETCH_SUCCESS,
  GRAB_LIST_FETCH_ERROR,
  GRAB_LIST_SORT_BY_COUNTRY,
  GRAB_LIST_SORT_BY_NUMBER,
  GRAB_DETAILS_FETCH_REQUEST,
  GRAB_DETAILS_FETCH_SUCCESS,
  GRAB_DETAILS_FETCH_ERROR,
  GRAB_DETAILS_FETCH_FAILURE,
  GRAB_ADD_STARTED,
  GRAB_ADD_SUCCESS,
  GRAB_ADD_ERROR,
  GRAB_DELETE_STARTED,
  GRAB_DELETE_SUCCESS,
  GRAB_DELETE_ERROR,
  GRAB_DELETE_FAILURE,
} from '../constants/grabs.js';


const SORT_NONE = 'SORT_NONE';
const SORT_COMPANY_ASC = 'SORT_COMPANY_ASC';
const SORT_COMPANY_DES = 'SORT_COMPANY_DES';
const SORT_NUMBER_ASC = 'SORT_COMPANY_ASC';
const SORT_NUMBER_DES = 'SORT_COMPANY_DES';

const compare = (a, b, asc = true) => {
  if (a < b) {
    if (asc) {
      return -1;
    }
    return 1;
  }
  else if (a > b) {
    if (asc) {
      return 1;
    }
    return -1;
  }
  return 0;
};

const grabs = (
  state = {
    sort: SORT_NONE,
    is_fetching: false,
    current: null,
    list: [],
    message_type: null,
    message: null,
  },
  action) => {
    switch (action.type) {
    case GRAB_ACTION_UNAUTHORIZED:
      return Object.assign({}, state, {
        is_fetching: false,
        message_type: 'warning',
        message: action.message,
      });

    case GRAB_LIST_FETCH_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true,
      });
    case GRAB_LIST_FETCH_SUCCESS:
      // Replace the list of grabs with the payload
      return Object.assign({}, state, {
        is_fetching: false,
        list: action.payload,
        last_updated: action.received_at,
        message_type: null,
        message: null,
      });
    case GRAB_LIST_FETCH_ERROR:
      return Object.assign({}, state, {
        is_fetching: false,
        last_updated: action.failed_at,
        message_type: 'error',
        message: action.message,
      });
    case GRAB_LIST_SORT_BY_COUNTRY:
      // If the current sort is anything but SORT_COMPANY_ASC, sort = SORT_COMPANY_ASC
      // if it's already SORT_COMPANY_ASC set it to SORT_COMPANY_DES
      let comp_sort = SORT_COMPANY_ASC;
      if (state.sort === SORT_COMPANY_ASC) {
        comp_sort = SORT_COMPANY_DES;
      }
      return Object.assign({}, state, {
        // Necessary to use arr.slice(0) to create a new object rather than inplace sorting
        list: state.list.slice(0).sort(
          (a, b) => compare(a.country, b.country, comp_sort === SORT_COMPANY_ASC)),
        sort: comp_sort,
      });
    case GRAB_LIST_SORT_BY_NUMBER:
      // If the current sort is anything but SORT_NUMBER_ASC, sort = SORT_NUMBER_ASC
      // if it's already SORT_NUMBER_ASC set it to SORT_NUMBER_DES
      let num_sort = SORT_NUMBER_ASC;
      if (state.sort === SORT_NUMBER_ASC) {
        num_sort = SORT_NUMBER_DES;
      }
      return Object.assign({}, state, {
        // Necessary to use arr.slice(0) to create a new object rather than inplace sorting
        list: state.list.slice(0).sort(
          (a, b) => compare(parseInt(a.number), parseInt(b.number), num_sort === SORT_NUMBER_ASC)),
        sort: num_sort,
      });

    case GRAB_DETAILS_FETCH_REQUEST:
      return Object.assign({}, state, {
        is_fetching: true,
        message_type: 'info',
        message: 'Started fetching details',
      });
    case GRAB_DETAILS_FETCH_SUCCESS:
      // Replace the current landgrab with the one from the payload
      return Object.assign({}, state, {
        is_fetching: false,
        current: action.payload,
        message_type: null,
        message: null,
      });
    case GRAB_DETAILS_FETCH_ERROR:
      return Object.assign({}, state, {
        is_fetching: false,
        message_type: 'error',
        message: action.message,
      });
    case GRAB_DETAILS_FETCH_FAILURE:
      return Object.assign({}, state, {
        is_fetching: false,
        message_type: 'warning',
        message: action.message,
      });

    case GRAB_ADD_STARTED:
      return Object.assign({}, state, {
        is_fetching: true,
        message_type: 'info',
        message: action.message,
      });
    case GRAB_ADD_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        message_type: 'info',
        message: action.message,
        current: null,
      });
    case GRAB_ADD_ERROR:
      return Object.assign({}, state, {
        is_fetching: false,
        message_type: 'error',
        message: action.message,
      });

    case GRAB_DELETE_STARTED:
      return Object.assign({}, state, {
        is_fetching: true,
        message_type: 'info',
        message: action.message,
      });
    case GRAB_DELETE_SUCCESS:
      return Object.assign({}, state, {
        is_fetching: false,
        message_type: 'info',
        message: action.message,
        current: null,
      });
    case GRAB_DELETE_FAILURE:
      return Object.assign({}, state, {
        is_fetching: false,
        message_type: 'warning',
        message: action.message,
      });
    case GRAB_DELETE_ERROR:
      return Object.assign({}, state, {
        is_fetching: false,
        message_type: 'error',
        message: action.message,
      });

    default:
      return state;
    }
  };

export default grabs;
