import fetch from 'cross-fetch';

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_ERROR,
  LOGIN_LOGOUT,
  LOGIN_HIDE_MESSAGE,
} from '../constants/login';

import {
  API_URL_BASE,
} from '../constants/index';

const API_URL = `${API_URL_BASE}/login`;


export const requestLogin = () => ({
  type: LOGIN_REQUEST,
});

export const loginError = message => ({
  type: LOGIN_ERROR,
  message: message,
});

export const loginFailure = json => ({
  type: LOGIN_FAILURE,
  json,
});

export const loginSuccess = json => ({
  type: LOGIN_SUCCESS,
  json,
});

export const tryToLogin = (email, password) => dispatch => {
  dispatch(requestLogin());
  return fetch(API_URL, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password })
  }).then(
    response => {
      window.setTimeout(() => {
        dispatch(hideMessage());
      }, 5000);
      if (!response.ok && (response.status !== 422)) {
        throw new Error('Network response was not ok.');
      }
      return response.json();
    }
  ).then(
    json => {
      // Hide any login messages after 5s
      if (json.error) {
        if (json.error.code === 422) {
          return dispatch(loginFailure(json));
        }
        return dispatch(loginError(json.error.message));
      }
      return dispatch(loginSuccess(json));
    }
  ).catch(
    err => dispatch(loginError(err.message))
  );
};


export const hideMessage = () => ({
  type: LOGIN_HIDE_MESSAGE,
});


export const logout = () => ({
  type: LOGIN_LOGOUT,
});


