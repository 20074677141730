import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class GoogleMap extends Component {
  componentDidMount() {
    this.initMap();
  }

  initMap() {
    let options = {
      zoom: this.props.zoom,
      center: this.props.center,
      mapTypeId: 'satellite',
    };

    let map = new window.google.maps.Map(
      document.getElementById("map"),
      options
    );

    if (this.props.geojson) {
      map.data.addGeoJson(this.props.geojson);
    }
  }

  render() {
    let style = {
      width: "500px",
      height: "500px",
    };

    return <div id="map" style={style}/>;
  }
}

GoogleMap.propTypes = {
  zoom: PropTypes.number.isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  geojson: PropTypes.object,
};
