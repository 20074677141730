import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

// import { BrowserRouter as Router } from 'react-router-dom';

import Main from '../components/Main';

import AuthOnlyRoute from './AuthOnlyRoute';
import Nav from './Nav.jsx';
import LandGrabList from './LandGrabList';
import LandGrabShow from './LandGrabShow';
import LandGrabAdd from './LandGrabAdd';

import configureStore, { history } from '../redux/configureStore';

const store = configureStore();

const Root = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <>
        <Nav />
        <Switch>
          <AuthOnlyRoute path="/locations/:id/edit" component={LandGrabAdd} />
          <AuthOnlyRoute path="/locations/add" component={LandGrabAdd} />
          <Route path="/locations/:id" component={LandGrabShow} />
          <Route path="/locations" component={LandGrabList} />
          <Route path="/" component={Main} />
        </Switch>
      </>
    </ConnectedRouter>
  </Provider>
);

export default Root;
