import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { has_error: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { has_error: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log('Error caught at boundary', error, info);
  }

  render() {
    if (this.state.has_error) {
      // You can render any custom fallback UI
      return <p>Something went wrong while rendering components.</p>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
