const API_PORT = 3123;

const WINDOW_HOST = window.location.hostname;

const PROD_API_HOST = 'grabapi.darkruby.org';
const DEV_API_HOST = WINDOW_HOST;

let api_protocol = 'http';
let api_port_part = `:${API_PORT}`;
let api_host = WINDOW_HOST;

if (/darkruby/.test(WINDOW_HOST) ||
    /amazonaws.com/.test(WINDOW_HOST)) {
  api_protocol = 'https';
  api_host = PROD_API_HOST;
  api_port_part = '';
}
else if (/localhost/.test(WINDOW_HOST) ||
         /127.0.0.1/.test(WINDOW_HOST) ||
         /192.168/.test(WINDOW_HOST)) {
  api_protocol = 'http';
  api_host = DEV_API_HOST;
  api_port_part = `:${API_PORT}`;
}
else {
  api_protocol = 'http';
  api_host = DEV_API_HOST;
  api_port_part = `:${API_PORT}`;
}

const API_VERSION = 'v1';
export const API_URL_BASE = `${api_protocol}://${api_host}${api_port_part}/api/${API_VERSION}`;
