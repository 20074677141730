import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Card from 'react-bootstrap/Card';

import {
  tryToLogin,
  logout,
} from '../actions/login';

class LoginFormDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu_open: false,
      email: "",
      password: "",
    };
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({[name]: value});
  }

  handleSubmit = event => {
    this.props.tryToLogin(this.state.email, this.state.password);
    event.preventDefault();
  }

  dropdownToggle = (new_value, event, {source}) => {
    if (new_value || source !== 'select') {
      this.setState({ menu_open: new_value });
    }
  }

  render() {
    const { email, message, errored, failed } = this.props;

    let optional_msg;
    if (message) {
      let type = "bg-secondary";
      if (failed) {
        type = "bg-warning";
      }
      else if (errored) {
        type = "bg-danger";
      }
      let css = `text-white ${type} mb-3`;
      optional_msg = <Card className={css}><Card.Body>{message}</Card.Body></Card>;
    }

    // Dropdown text is the user's email when they're logged in
    const dropdown_text = email ? email : "Login";

    const input_width_css = {
      minWidth: '20em',
    };

    const login_form = (
      <Form onSubmit={e => this.handleSubmit(e)}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control style={input_width_css}
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder="foo@example.com" />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control style={input_width_css}
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        placeholder="Password" />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    );

    const logout_button = (
      <Button onClick={this.props.logout}>Logout</Button>
    );

    return (
      <Dropdown show={this.state.menu_open}
                onToggle={this.dropdownToggle}
                as={NavItem}
                alignRight>
        <Dropdown.Toggle as={NavLink} key="toggle">{dropdown_text}</Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="p-3">
            {optional_msg}
            {email ? logout_button : login_form}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

LoginFormDropdown.propTypes = {
  email: PropTypes.string,
  message: PropTypes.string,
  errored: PropTypes.bool,
  failed: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {email, message, errored, failed} = state.login;
  return {email, message, errored, failed};
};


const map_dispatch_to_props = {
  tryToLogin,
  logout,
};

export default connect(mapStateToProps, map_dispatch_to_props)(LoginFormDropdown);
