import React from 'react';
import ReactDependentScript from "react-dependent-script";
import ErrorBoundary from '../components/ErrorBoundary';
import GoogleMap from '../components/GoogleMap';


const GoogleMapsContainer = props => (
  <ReactDependentScript
    scripts={[
      `https://maps.googleapis.com/maps/api/js?key=${props.maps_api_key}`
    ]}>
    <ErrorBoundary>
      <GoogleMap center={props.center} zoom={12} geojson={props.geojson} />
    </ErrorBoundary>
  </ReactDependentScript>
);

export default GoogleMapsContainer;
